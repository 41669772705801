import { DropboxResponseError } from "@dropbox/api-v2-client";
import type { Service as AuditLoggingService } from "@mirage/service-audit-logging";
import provideAuditLoggingService from "@mirage/service-audit-logging/service";
import type { Service as AuthService } from "@mirage/service-auth";
import {
  getInstallId,
  getSessionId,
  ApiEnvironment,
} from "@mirage/service-auth";
import provideAuthService from "@mirage/service-auth/service";
import provideCalendarEventsService from "@mirage/service-calendar-events/service";
import provideCloudDocsService from "@mirage/service-cloud-docs/service";
import provideComposeService from "@mirage/service-compose/service";
import provideConnectorsService from "@mirage/service-connectors/service";
import type { Service as DbxApiService } from "@mirage/service-dbx-api";
import provideDbxApiService from "@mirage/service-dbx-api/service";
import { EnvCtx } from "@mirage/service-environment-context/global-env-ctx";
import provideEnvironmentContextService from "@mirage/service-environment-context/service";
import { getWebPlatformForEnvCtx } from "@mirage/service-environment-context/web-platform";
import provideExperimentationService from "@mirage/service-experimentation/service";
import Growthbook from "@mirage/service-experimentation/service/growthbook";
import Stormcrow from "@mirage/service-experimentation/service/stormcrow";
import provideFeatureRingSettingsService from "@mirage/service-feature-ring-settings/service/provider";
import provideFeedService from "@mirage/service-feed/service";
import provideFeedbackService from "@mirage/service-feedback/service";
import { earlyInitIpcProfilingForBackground } from "@mirage/service-ipc-profiling";
import provideIpcProfilingService, {
  IpcProfilingStorage,
} from "@mirage/service-ipc-profiling/service";
import provideKeymapActionsService from "@mirage/service-keymap-actions/service";
import { WebKeymapActionsAdapter } from "@mirage/service-keymap-actions/web-adapter";
import * as loggingService from "@mirage/service-logging";
import provideLoggingService from "@mirage/service-logging/service";
import datadogTransport from "@mirage/service-logging/service/transports/datadog";
import type { LogoutServiceConsumerContract } from "@mirage/service-logout";
import * as logoutServiceConsumer from "@mirage/service-logout";
import type { Service as LogoutService } from "@mirage/service-logout/service";
import provideLogoutService from "@mirage/service-logout/service";
import provideOnboardingService from "@mirage/service-onboarding/service";
import provideOnboardingChecklistService from "@mirage/service-onboarding-checklist/service";
import * as operationalMetricsService from "@mirage/service-operational-metrics";
import { namespace } from "@mirage/service-operational-metrics";
import { measureLatencySync } from "@mirage/service-operational-metrics/measure-latency";
import { logPageLoadMilestone } from "@mirage/service-operational-metrics/page-load";
import provideOperationalMetricsService from "@mirage/service-operational-metrics/service";
import providePeopleService from "@mirage/service-people/service";
import providePlatformActionsService from "@mirage/service-platform-actions/service";
import { WebPlatformActionsAdapter } from "@mirage/service-platform-actions/web-adapter";
import provideProductLoggingService from "@mirage/service-product-logging/service";
import provideRecentContentService from "@mirage/service-recent-content/service";
import provideRecommendedStacksService from "@mirage/service-recommended-stacks/service";
import provideResultActionsService from "@mirage/service-result-actions/service";
import provideSearchService from "@mirage/service-search/service";
import provideSettingActionsService from "@mirage/service-setting-actions/service";
import { WebSettingActionsAdapter } from "@mirage/service-setting-actions/web-adapter";
import provideSettingsService from "@mirage/service-settings/service";
import provideStackAdminService from "@mirage/service-stack-admin-settings/service";
import provideStackSuggestionsService from "@mirage/service-stack-suggestions/service";
import provideStacksService from "@mirage/service-stacks/service";
import type { Service as TypeaheadSearchService } from "@mirage/service-typeahead-search";
import { searchServiceCachingStrategy } from "@mirage/service-typeahead-search";
import provideTypeaheadSearchService from "@mirage/service-typeahead-search/service";
import provideUrlMetadataService from "@mirage/service-url-metadata/service";
import ocache from "@mirage/shared/async/observable-map-cache";
import { getOperatingSystem } from "@mirage/shared/util/os";
import {
  isDevBuildChannel,
  isStagingBuildChannel,
  isTestBuildChannel,
  USER_AGENT_INFO,
} from "@mirage/shared/util/tiny-utils";
import { KVStorage } from "@mirage/storage";
import { largeToCachedStorage } from "@mirage/storage/large-kv-store/large-to-kv-store";
import LocalStorage from "@mirage/storage/local-storage";
import legacyApiv2MetricSink from "@mirage/webapp/helpers/metricSink";
import {
  DBID_KEY,
  setURLParamInLocalStorage,
} from "@mirage/webapp/helpers/readUrlParam";
import { RoutePath } from "@mirage/webapp/routeTypes";
import * as Sentry from "@sentry/react";
import { LogLevels } from "consola";
import * as rx from "rxjs";
import * as op from "rxjs/operators";

import { AUTH_STORAGE } from "./AuthStorage";
import { LogoutAdapter } from "./helpers/Logout";
import { getProductLoggingServiceConfig } from "./helpers/productLoggingConfig";
import { REDIRECT_TO_PATH_URL_PARAM } from "./pages/Login";
import { Config } from "./shared/config";
import { WebEnvCtx } from "./shared/envCtx";
import { getWebAuthCookie, removeWebAuthCookie } from "./shared/webCookies";
import { sharedWorkerDatabase } from "./shared-worker/sharedWorkerStorage";

function getKeyedStore<T>(key: string) {
  return largeToCachedStorage<T>(key, sharedWorkerDatabase);
}

const isDev = isDevBuildChannel(Config.BUILD_CHANNEL);

const platform = getWebPlatformForEnvCtx();

const isDevOrStage =
  isDevBuildChannel(Config.BUILD_CHANNEL) ||
  isStagingBuildChannel(Config.BUILD_CHANNEL);

const isTest = isTestBuildChannel(Config.BUILD_CHANNEL);

export async function startMirageServices() {
  logPageLoadMilestone("startMirageServices start");

  // Attach IPC profiler early to catch IPCs during services init.
  // Webapp backend and UI are inside the same process.
  const ipcProfilingStore = getKeyedStore<IpcProfilingStorage>("ipc-profiling");
  earlyInitIpcProfilingForBackground(ipcProfilingStore);

  void envSetup(); // no need to await anymore
  const logoutServiceImpl = provideLogoutService(
    loggingService,
    new LogoutAdapter(),
  );
  adapters();
  const { authServiceImpl } = await auth(logoutServiceImpl);
  const { dbxApiServiceImpl } = await api(
    authServiceImpl,
    logoutServiceConsumer,
  );
  const { auditLoggingServiceImpl } = await logging(
    ipcProfilingStore,
    authServiceImpl,
    dbxApiServiceImpl,
  );
  feedback(authServiceImpl, dbxApiServiceImpl);
  gating(authServiceImpl, dbxApiServiceImpl);
  onboarding(dbxApiServiceImpl, auditLoggingServiceImpl);
  connectors(dbxApiServiceImpl);
  const { typeaheadSearchServiceImpl } = await search(
    dbxApiServiceImpl,
    logoutServiceConsumer,
  );
  stacks(authServiceImpl, dbxApiServiceImpl, logoutServiceConsumer);
  startPage(dbxApiServiceImpl, logoutServiceConsumer);
  people(dbxApiServiceImpl, typeaheadSearchServiceImpl, logoutServiceConsumer);
  composePage(logoutServiceConsumer);

  logPageLoadMilestone("startMirageServices end");
  return { logoutServiceImpl };
}

async function envSetup() {
  // In the webapp, there is only one process, so this will initialize the UI
  // env ctx as well.
  await provideEnvironmentContextService(
    LocalStorage("environment-context"),
    WebEnvCtx,
  );
}

function adapters() {
  provideSettingsService(LocalStorage("settings"), { overrides: {} });
  provideResultActionsService(new WebPlatformActionsAdapter());

  providePlatformActionsService(new WebPlatformActionsAdapter());
  provideSettingActionsService(new WebSettingActionsAdapter());
  provideKeymapActionsService(new WebKeymapActionsAdapter());
}

async function auth(logoutService: LogoutService) {
  try {
    const authCookie = getWebAuthCookie();
    if (
      authCookie &&
      // Don't overwrite the existing auth tokens in staging.
      !AUTH_STORAGE.get("accessToken") &&
      !AUTH_STORAGE.get("refreshToken")
    ) {
      AUTH_STORAGE.set("accessToken", authCookie.accessToken);
      AUTH_STORAGE.set("refreshToken", authCookie.refreshToken);
      AUTH_STORAGE.set("accessTokenExpiresAt", authCookie.accessTokenExpiresAt);
      AUTH_STORAGE.set("uid", authCookie.uid);

      // Clear Cookies afterwards
      removeWebAuthCookie();
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  const authServiceImpl = await provideAuthService(
    AUTH_STORAGE,
    {
      domain: Config.API_SERVER || undefined,
      clientId: Config.OAUTH_CLIENT_KEY,
      clientSecret: Config.OAUTH_CLIENT_SECRET,
      // Important: navigate without page refresh, otherwise login won't work.
      openURL: (url) => location.assign(url),
      oauthRedirectURL:
        Config.OAUTH_REDIRECT_URL || `${location.origin}${RoutePath.OAUTH}`,
      loginRedirectConfig: {
        redirectToPathURLParam: REDIRECT_TO_PATH_URL_PARAM,
        rootRoutePath: RoutePath.ROOT,
        loginRoutePath: RoutePath.LOGIN,
      },
      apiEnvironment: ApiEnvironment.PROD,
    },
    logoutServiceConsumer,
    (e) => {
      if (
        e instanceof DropboxResponseError &&
        (e.status === 401 ||
          (e.status === 400 && e?.error?.error === "invalid_grant"))
      ) {
        // Unauthorized error from Dropbox API, logout user
        Sentry.captureMessage(
          `Logging out user from Auth service, error - ${JSON.stringify(
            e?.error,
          )}`,
        );
        logoutService.logout();
      }
      // onError
      Sentry.captureException(e);
    },
  );

  return { authServiceImpl };
}

async function api(
  authService: AuthService,
  logoutService: LogoutServiceConsumerContract,
) {
  const dbxApiServiceImpl = await provideDbxApiService(
    authService,
    logoutService,
    // Prevent infinite loop with reporting latency for the metrics api.
    // TODO: Remove stacksReportClientMetrics once clientMetricsRecord is stable.
    new Set(["stacksReportClientMetrics", "clientMetricsRecord"]),
  );

  provideOperationalMetricsService(
    // TODO: Replace with apiV2MetricsSink later.
    legacyApiv2MetricSink({
      artifactName: "dash-web",
      rootNamespace: "dash_web",
      environment: EnvCtx.environment,
      globalTags: {
        environment: EnvCtx.environment,
        osName: USER_AGENT_INFO.os.name ?? "unknown",
        osType: getOperatingSystem() ?? "other",
        platform: EnvCtx.platform,
        channel: EnvCtx.buildChannel,
        browser: USER_AGENT_INFO.browser.name ?? "unknown",
      },
    }),
  );

  return { dbxApiServiceImpl };
}

function feedback(authService: AuthService, dbxApiService: DbxApiService) {
  provideFeedbackService(authService, dbxApiService, "webapp");
}

function gating(authService: AuthService, dbxApiService: DbxApiService) {
  const ringService = provideFeatureRingSettingsService(
    getKeyedStore("feature-rings"),
    dbxApiService,
  );

  provideExperimentationService({
    adapters: [
      Growthbook({
        clientKey: Config.GROWTHBOOK_CLIENT_KEY,
        attributes: {
          app_version: Config.BUILD_VERSION,
          os_name: platform,
          platform: "web",
        },
        enableDevMode: isDevOrStage,
      }),
      Stormcrow(dbxApiService),
    ],
    storage: sharedWorkerDatabase,
    authService,
    ringService,
  });
}

function onboarding(
  dbxApiService: DbxApiService,
  auditLoggingService: AuditLoggingService,
) {
  provideOnboardingService(
    LocalStorage("onboarding"),
    "web",
    dbxApiService,
    loggingService,
    auditLoggingService,
  );
  provideOnboardingChecklistService(dbxApiService, loggingService);
}

function connectors(dbxApiService: DbxApiService) {
  provideConnectorsService(
    LocalStorage("mirage-connectors"),
    [],
    dbxApiService,
    operationalMetricsService,
  );
}

async function search(
  dbxApiService: DbxApiService,
  logoutService: LogoutServiceConsumerContract,
) {
  provideSearchService(
    searchServiceCachingStrategy,
    [],
    dbxApiService,
    loggingService,
    { namespace, measureLatencySync },
  );

  const typeaheadSearchServiceImpl = await provideTypeaheadSearchService(
    getKeyedStore("mirage-search-cache"),
    [],
    logoutService,
  );
  return { typeaheadSearchServiceImpl };
}

function stacks(
  authService: AuthService,
  dbxApiService: DbxApiService,
  logoutService: LogoutServiceConsumerContract,
) {
  provideStacksService(
    sharedWorkerDatabase,
    dbxApiService,
    authService,
    logoutService,
    isDevOrStage,
    isTest,
    Config.BOLT_URL,
  );
  provideStackSuggestionsService(
    getKeyedStore("stack-suggestions"),
    dbxApiService,
  );
  provideRecommendedStacksService(
    getKeyedStore("recommended-stacks"),
    dbxApiService,
  );
  provideUrlMetadataService(getKeyedStore("url-metadata"), dbxApiService);
  provideStackAdminService(
    getKeyedStore("stack-admin-settings"),
    dbxApiService,
  );
  provideCloudDocsService(dbxApiService);
}

function startPage(
  dbxApiService: DbxApiService,
  logoutService: LogoutServiceConsumerContract,
) {
  provideCalendarEventsService(
    getKeyedStore("calendar-events"),
    dbxApiService,
    logoutService,
  );
  provideRecentContentService(
    getKeyedStore("recent-content"),
    dbxApiService,
    logoutService,
  );
  provideFeedService(
    getKeyedStore("activity-feed"),
    dbxApiService,
    logoutService,
  );
}

export function composePage(logoutService: LogoutServiceConsumerContract) {
  provideComposeService(
    getKeyedStore("compose-sessions"),
    getKeyedStore("compose-voices"),
    logoutService,
  );
}

function people(
  dbxApiService: DbxApiService,
  typeaheadSearchService: TypeaheadSearchService,
  logoutService: LogoutServiceConsumerContract,
) {
  providePeopleService(
    dbxApiService,
    typeaheadSearchService,
    logoutService,
    loggingService,
  );
}

async function logging(
  ipcProfilingStore: KVStorage<IpcProfilingStorage>,
  authService: AuthService,
  dbxAPiService: DbxApiService,
) {
  const context$ = rx
    .zip(rx.from(getInstallId()), rx.from(getSessionId()))
    .pipe(op.map(([installId, sessionId]) => ({ installId, sessionId })));

  const ctxcache = await ocache([
    rx.of({
      version: Config.BUILD_VERSION,
      platform,
      onboardingDbid: setURLParamInLocalStorage(DBID_KEY),
    }),
    authService.listenForAccountIds(),
    context$,
  ]);

  const transport = datadogTransport(
    Config.DATADOG_CLIENT_TOKEN,
    Config.DATADOG_SERVICE_ID,
    Config.ENV,
    ctxcache,
    getKeyedStore("logging"),
  );

  provideLoggingService(
    [
      {
        transport,
        level: LogLevels.info,
      },
    ],
    Config.BUILD_CHANNEL === "production" ? LogLevels.silent : LogLevels.debug,
  );

  provideProductLoggingService(getProductLoggingServiceConfig(isDev));

  const auditLoggingServiceImpl = provideAuditLoggingService(
    authService,
    dbxAPiService,
  );

  provideIpcProfilingService(ipcProfilingStore);

  return { auditLoggingServiceImpl };
}

// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3610
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashSearchResultType } from "../enums/dash_search_result_type";
import type { SearchResultSource } from "../enums/search_result_source";
import type { DashSourceType } from "../enums/dash_source_type";
import type { SerpFinalRanking } from "../enums/serp_final_ranking";
import type { DashCurationType } from "../enums/dash_curation_type";

// Event_name: 'create.dash_curation'
// Description: Fires when the dash curation is created. As of 12/24 this is only used for admins verifying results. PRD: https://www.dropbox.com/scl/fi/khy0pe92xk0qq5nc45zwo/PRD-Verified-Search-Results.paper?rlkey=9ltjajtz9d58vwafww3l3nkak&dl=0
// Owner: dash-exp-find-and-discover
export type Create_DashCuration = PAPEventGeneric<
  'dash',
  'create',
  'dash_curation',
  {
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // general property for attribution/referrer information in an event. Values are provided from the client from a hardcoded list. Usage will vary by event.
    source?: string;
    // Describe whether a search result came from upstream search
    fromUpstream?: boolean;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Legacy: dash_connector_id in Desktop
    // Note, this should have been a enum :(
    connector?: string;
    // Total result score of ranking.
    totalScore?: number;
    // Timestamp that encodes last time the search result was updated (in milliseconds).
    // i.e Document.upstreamUpdatedAt, Event.upstreamUpdatedAt, etc.
    // 1680559512000, 1680569512000, etc.
    resultUpdatedAtMs?: number;
    // Boolean indicating when the right rail is visible (in Desktop)
    rightRailOpened?: boolean;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // This defines where the document source is
    dashSourceType?: DashSourceType;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // Which algorithm the Dash client used while drawing SERP results onto the screen. This reflects the logic the client used to merge and rank server results, upstream results, and local file results.
    serpFinalRanking?: SerpFinalRanking;
    // The zero-index returned position of search result in a list of search results with the SERP CTA removed from the list
    resultPositionNoCta?: number;
    // The type of curation applied to a dash search result.
    dashCurationType?: DashCurationType;
    // The # count of exact queries associated with a verification curation applied to a search result
    dashVerificationQueryCount?: number;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
  }
>;

export function PAP_Create_DashCuration(properties?: Create_DashCuration['properties']): Create_DashCuration {
  return <Create_DashCuration>{
    class: 'dash',
    action: 'create',
    object: 'dash_curation',
    properties,
  };
}

import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import {
  ComposeAssistantConversationMessage,
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { memo, useEffect, useRef } from 'react';
import {
  ConversationMessageRow,
  ConversationMessageRowMessageSourceRow,
} from './ConversationMessages';
import styles from './SourcesFromSettingsConversationMessages.module.css';

interface SourcesFromSettingsConversationMessagesProps {
  messages: ComposeAssistantConversationMessage[];
  sources: ComposeSource[];
  onRemoveSource: (source: ComposeSource) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  loadingMessage?: React.ReactNode;
}
export const SourcesFromSettingsConversationMessages = memo(
  ({
    messages,
    sources,
    onRemoveSource,
    logComposeEvent,
    loadingMessage,
  }: SourcesFromSettingsConversationMessagesProps) => {
    // auto-scroll as new messages are added
    const scrollerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
      }
    }, [messages]);
    return (
      <div className={styles.ConversationMessagesScroller} ref={scrollerRef}>
        <div className={styles.ConversationMessages}>
          {sources.map((source, i) => (
            <div
              className={styles.ContextInputSourcesChipRow}
              key={getSourceUUID(source) || i}
            >
              <div>
                <div className={styles.ContextInputSourcesChip}>
                  <ConversationMessageRowMessageSourceRow source={source} />
                </div>
              </div>
            </div>
          ))}
          {messages.map((message, i) => (
            <ConversationMessageRow
              key={i}
              variant="condensed"
              message={message}
              onRemoveSource={onRemoveSource}
              isLastMessage={i === messages.length - 1}
              logComposeEvent={logComposeEvent}
              onOpenArtifact={() => {}}
              showReactions={false}
            />
          ))}
          {loadingMessage}
        </div>
      </div>
    );
  },
);
SourcesFromSettingsConversationMessages.displayName =
  'SourcesFromSettingsConversationMessages';

import { SearchResult } from '@mirage/service-dbx-api';

import type { dash } from '@dropbox/api-v2-client';

export const VERIFIED_CURATION_TAG: dash.CurationType = {
  '.tag': 'curation_type_verified',
};

export function isVerifiedCuration(curation: dash.Curation) {
  return curation.curation_type?.['.tag'] === VERIFIED_CURATION_TAG['.tag'];
}

export function getVerification(result: SearchResult) {
  return result.curations?.find((curation) => isVerifiedCuration(curation));
}

export function isResultVerified(result: SearchResult): boolean {
  return !!getVerification(result);
}

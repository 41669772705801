import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { isStagingBuildChannel } from '@mirage/shared/util/tiny-utils';
import { useCallback, useEffect, useState } from 'react';
import {
  clearStoredApiEnvironment,
  getApiEnvironment,
  setApiEnvironment,
} from './';
import { ApiEnvironment } from './types';

const logger = tagged('useApiEnvironment');

export function useApiEnvironment() {
  const [environment, setEnvironment] = useState<ApiEnvironment | null>(
    ApiEnvironment.PROD,
  );

  const shouldUseStageBackendFromGrowthbook = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_17_use_stage_backend'),
  );
  const isStaging = isStagingBuildChannel(EnvCtx.buildChannel);

  useEffect(() => {
    if (shouldUseStageBackendFromGrowthbook || isStaging) {
      setEnvironment(ApiEnvironment.STAGE);
      setApiEnvironment(ApiEnvironment.STAGE);
    } else {
      getApiEnvironment()
        .then(setEnvironment)
        .catch((error) => {
          logger.error('Failed to get API environment', error);
        });
    }
  }, [shouldUseStageBackendFromGrowthbook, isStaging]);

  const onChange = useCallback((value: ApiEnvironment | null) => {
    if (value) {
      setEnvironment(value);
      setApiEnvironment(value);
    } else {
      clearStoredApiEnvironment()
        .then(setEnvironment)
        .catch((error) => {
          logger.error('Failed to clear and get API environment', error);
        });
    }
  }, []);

  return [environment, onChange] as const;
}

import {
  deleteComposeApiVoice,
  loadComposeApiVoices,
  loadComposeVoices as loadLocalComposeVoices,
  saveComposeApiVoice,
  saveComposeVoices,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const logger = tagged('ComposeVoicesContext');

export interface ComposeVoicesContextInterface {
  voices: ComposeVoice[] | undefined;
  // promises are resolved when persistence is complete
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  deleteVoice: (voiceID: string) => Promise<void>;
}
export const ComposeVoicesContext =
  createContext<ComposeVoicesContextInterface | null>(null);
export const useComposeVoicesContext = () => {
  const context = useContext(ComposeVoicesContext);
  if (!context) {
    throw new Error(
      'useComposeVoicesContext must be used within a ComposeVoicesContextProvider',
    );
  }
  return context;
};

interface ComposeVoicesContextProviderProps {
  children: React.ReactNode;
}
export const ComposeVoicesContextProvider = ({
  children,
}: ComposeVoicesContextProviderProps) => {
  const [voices, setVoices] = useState<ComposeVoice[] | undefined>(undefined);
  useEffect(() => {
    async function loadVoices() {
      // TODO (will): Remove after 01-12-2024 all local voices should be synced by then
      // save any local voices to API then delete them
      const localVoices = await loadLocalComposeVoices();
      if (localVoices.length) {
        await Promise.all(localVoices.map(saveComposeApiVoice))
          .then(() => {
            saveComposeVoices([]);
            return;
          })
          .catch();
      }
      const apiVoices = await loadComposeApiVoices();
      setVoices(apiVoices);
    }
    loadVoices();
  }, []);

  const saveVoice = useCallback((voice: ComposeVoice) => {
    return new Promise<void>((resolve) => {
      setVoices((prevVoices) => {
        if (prevVoices === undefined) {
          logger.warn('attempted to save voice before loading completes');
          resolve();
          return prevVoices;
        }

        // update voice if it already exists, otherwise add it to the array
        let found = false;
        const updatedVoices = prevVoices.map((v) => {
          if (v.id === voice.id) {
            found = true;
            return voice;
          }
          return v;
        });
        if (!found) {
          updatedVoices.push(voice);
        }
        saveComposeApiVoice(voice)
          .then(() => {
            return resolve();
          })
          .catch();
        return updatedVoices;
      });
    });
  }, []);

  const deleteVoice = useCallback((voiceID: string) => {
    return new Promise<void>((resolve) => {
      setVoices((prevVoices) => {
        if (prevVoices === undefined) {
          logger.warn('attempted to delete voice before loading completes');
          resolve();
          return prevVoices;
        }

        const voice = prevVoices.find((v) => v.id === voiceID);
        if (voice) {
          deleteComposeApiVoice(voice)
            .then(() => {
              return resolve();
            })
            .catch();
        } else {
          resolve();
        }

        const updatedVoices = prevVoices.filter((v) => v.id !== voiceID);
        return updatedVoices;
      });
    });
  }, []);

  return (
    <ComposeVoicesContext.Provider value={{ voices, saveVoice, deleteVoice }}>
      {children}
    </ComposeVoicesContext.Provider>
  );
};

import {
  createGrpcWebTransport,
  createPromiseClient,
} from '@bufbuild/connect-web';
import { getAccessToken, getApiEnvironment } from '@mirage/service-auth';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { getAll as getSettings } from '@mirage/service-settings';

import type { Interceptor, PromiseClient } from '@bufbuild/connect-web';
import type { ServiceType } from '@bufbuild/protobuf';

export const API_V2_GRPC_WEB_BASE_URL =
  'https://api.dropboxapi.com/web-grpc/2/';

const logger = tagged('grpc/api-v2');

export const apiV2HeadersInterceptor: Interceptor = (next) => async (req) => {
  const allHeaders: { [key: string]: string } = {};
  const accessToken = await getAccessToken();
  const environment = await getApiEnvironment();
  const languagePickerEnabled = convertFeatureValueToBool(
    await getCachedOrFetchFeatureValue('dash_2024_11_21_language_picker'),
  );
  const { locale } = await getSettings();

  if (!accessToken) {
    throw new Error(
      'Access token is required to make API v2 requests. Please ensure the user is authenticated.',
    );
  }
  allHeaders['Authorization'] = `Bearer ${accessToken}`;
  if (environment === 'stage') {
    allHeaders['x-dropbox-backend'] = 'stage';
    logger.log('Using stage backend for gRPC API v2 requests');
  } else if (environment === 'validation') {
    allHeaders['x-dropbox-backend'] = 'stage';
    allHeaders['x-dropbox-validation'] = 'val';
    logger.log('Using validation backend for gRPC API v2 requests');
  }
  if (languagePickerEnabled && locale) {
    allHeaders['X-Dropbox-Locale'] = locale;
    allHeaders['Dropbox-API-User-Locale'] = locale;
  }

  Object.entries({
    ...allHeaders,
  })
    .filter(([_, value]) => !!value)
    // if this is a custom header (starts with x-) and it was already set, don't override
    .filter(
      ([name, _]) =>
        !(name.toLowerCase().startsWith('x-') && req.header.has(name)),
    )
    .forEach(([name, value]) => {
      if (value && !req.header.has(name)) {
        req.header.set(name, value as string);
      }
    });
  return await next(req);
};

/**
 * Returns API v2 GRPC Web client.
 * @param service a service definition.
 * @param baseUrl an optional base url for requests.
 * @returns a promise client for making API v2 GRPC Web requests.
 */
export function createAPIv2GRPCWebPromiseClient<T extends ServiceType>(
  service: T,
  baseUrl: string = API_V2_GRPC_WEB_BASE_URL,
): PromiseClient<T> {
  const transport = createGrpcWebTransport({
    baseUrl,
    interceptors: [apiV2HeadersInterceptor],
  });
  return createPromiseClient(service, transport);
}

import type { SearchResult } from '@mirage/service-dbx-api/service/search';

/**
 * This file should be removed once the data model is updated to support structured data for the new fields.
 *
 * Saleforce stopgap for Q4 2024 connectors work. Currently the connectors team is just putting extra metadata into the
 * body content of a search result. This function will parse that data out and return it as a separate object.
 *
 * In Q1 2024 the data model will be updated to support structured data for the new fields, until then
 * we have to use this hack... :(
 */
export function formatSalesforceResult(
  searchResult: SearchResult,
): SearchResult {
  if (searchResult.connectorInfo?.connectorName !== 'salesforce') {
    return searchResult;
  }

  const salesforceExtraData = parseSalesforceExtraData(
    searchResult.highlights?.body?.text?.[0] || null,
  );

  if (!salesforceExtraData) {
    return searchResult;
  }
  const { description, amount, closeDate, stageName, status, subject } =
    salesforceExtraData;

  if (description && searchResult?.highlights?.body?.text?.[0]) {
    searchResult.highlights.body.text[0] = description;
  }

  // Case types have a subject in the body that needs to be appended to the title
  if (subject) {
    searchResult.title = `${searchResult.title} ${subject}`;
  }
  if (amount || closeDate || stageName || status) {
    searchResult.fileTypeExtraData = {
      salesforce: {
        amount,
        closeDate,
        stageName,
        status,
      },
    };
  }

  return searchResult;
}

interface ParsedSalesforceData {
  description: string;
  amount: number;
  closeDate: string;
  stageName: string;
  status: string;
  subject: string;
  // fields we are passing but not parsing FolderName (Dashboards), Job Title (Contacts)
}

const FIELD_PREFIXES = {
  description: 'Description - ',
  amount: 'Amount - ',
  closeDate: 'CloseDate - ',
  stageName: 'StageName - ',
  status: 'Status - ',
  subject: 'Subject - ',
};

function generateRegexForField(fieldKey: keyof typeof FIELD_PREFIXES): RegExp {
  const otherPrefixes = Object.values(FIELD_PREFIXES).filter(
    (prefix) => prefix !== FIELD_PREFIXES[fieldKey],
  );

  const boundaryPattern =
    otherPrefixes.length > 0 ? `(?: ${otherPrefixes.join('|')}|$)` : `$`;
  return new RegExp(`${FIELD_PREFIXES[fieldKey]}(.*?)${boundaryPattern}`);
}

function parseSalesforceExtraData(
  input: string | null,
): Partial<ParsedSalesforceData> | null {
  if (!input) return null;

  const patterns: Record<keyof typeof FIELD_PREFIXES, RegExp> = {
    description: generateRegexForField('description'),
    amount: new RegExp(`${FIELD_PREFIXES.amount}([\\d.]+)`),
    closeDate: new RegExp(`${FIELD_PREFIXES.closeDate}(\\d{4}-\\d{2}-\\d{2})`),
    stageName: generateRegexForField('stageName'),
    status: generateRegexForField('status'),
    subject: generateRegexForField('subject'),
  };

  const fields: Partial<ParsedSalesforceData> = {};

  for (const [key, pattern] of Object.entries(patterns) as [
    keyof ParsedSalesforceData,
    RegExp,
  ][]) {
    const match = input.match(pattern);
    if (match) {
      if (key === 'amount') {
        fields[key] = parseFloat(match[1]);
      } else {
        fields[key] = match[1].trim();
      }
    }
  }

  if (fields.subject) {
    fields.subject = fields.subject.replace(/<\/?em>/g, '');
  }
  return fields;
}

import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { VerifiedFill } from '@dropbox/dig-icons/assets';
import { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import {
  DATA_ATTRIBUTE_SELECTED,
  DATA_ATTRIBUTE_SELECTION_ID,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { TitleHighlighter } from '@mirage/search/SearchResults/TitleHighlighter';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { mouseActivityAtom } from '@mirage/shared/hooks/useInitDetectMouseActivity';
import { hotkeys } from '@mirage/shared/hotkeys';
import {
  getKeyMaps,
  getMousetrapMap,
  getOsModifierKeys,
} from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AUX_CLICK_BUTTON } from './constants';
import { ResultActions } from './ResultActions';
import styles from './ResultItem.module.css';
import { VerifiedPopup } from './VerifiedPopup';

import type { dash } from '@dropbox/api-v2-client';
import type { DropdownItem } from '@mirage/search/General/Dropdown';
import type { DisplayAction } from '@mirage/search/SearchResults/ResultRow';
import type { Handler, Handlers } from '@mirage/shared/hotkeys';

type ResultItemProps = {
  title: string;
  query?: string;
  selectionId: string;
  subtext?: React.ReactNode;
  bodyContent?: React.ReactNode;
  icon: React.ReactNode;
  selected?: boolean;
  onSelectItem: (selectionId: string) => void;
  onLaunch: (launchMethod: LaunchMethod) => void;
  onShown?: () => void;
  displayedActions: DisplayAction[];
  dropdownActions?: Array<Array<DropdownItem>>;
  colorVariant?: 'normal' | 'subtle';
  onOpenDropdown?: (isOpen: boolean) => void;
  peekResultActions?: boolean;
  debugUuid?: string;
  stringForClipboard?: string; // If defined, user can copy this string to clipboard by pressing CMD+C on the result.
  onCopy?: () => void; // Callback when CMD+C is pressed on the result.
  animate?: boolean;
  delayMs?: number;
  alignActionsTop?: boolean;
  noMargin?: boolean;
  displayOnly?: boolean;
  onAddToStack?: () => void;
  onSummarize?: () => void;
  verification?: dash.Curation;
  resultUuid?: string;
};

export const ResultItem = ({
  query,
  stringForClipboard,
  selectionId,
  title,
  subtext,
  bodyContent,
  icon,
  selected,
  onSelectItem,
  onLaunch,
  onShown,
  displayedActions,
  dropdownActions,
  colorVariant = 'normal',
  onOpenDropdown,
  peekResultActions = false,
  debugUuid,
  onCopy,
  animate = false,
  delayMs,
  alignActionsTop = false,
  noMargin = false,
  displayOnly = false,
  onAddToStack,
  onSummarize,
  verification,
  resultUuid,
}: ResultItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const verifiedRef = useRef<HTMLDivElement>(null);
  const verifiedTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [hovered, setHovered] = useState(false);
  const [openVerified, setOpenVerified] = useState(false);
  const [hasBodyContent, setHasBodyContent] = useState(true);
  const firedShownCallback = useRef(false);
  const isMouseActive = useAtomValue(mouseActivityAtom);
  const isMobileSize = useIsMobileSize();
  const showHoverState = isMouseActive && hovered && !displayOnly;
  const showResultActions =
    (peekResultActions || selected || showHoverState) && !isMobileSize;
  const isDesktop = EnvCtx.surface === 'desktop';
  const platform = EnvCtx.platform;

  const verifiedSearchResultsEnabled =
    useFeatureFlagValue('dash_2024_10_30_verified_search_results', false) ===
    'ON';

  // Monitor when result item comes into view and fire onShown callback
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !firedShownCallback.current) {
        firedShownCallback.current = true;
        onShown?.();
      }
    });

    const containerElement = containerRef.current;
    if (containerElement) {
      observer.observe(containerElement);
    }
    return () => {
      if (containerElement) {
        observer.unobserve(containerElement);
      }
    };
  }, [containerRef, onShown]);

  useEffect(() => {
    const element = bodyRef.current;
    const checkHeight = () => {
      if (element) {
        const height = element.offsetHeight;
        setHasBodyContent(height > 0);
      }
    };
    checkHeight();
    const resizeObserver = new ResizeObserver(() => {
      checkHeight();
    });

    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [bodyRef]);

  const handleOpenItem = useCallback(
    (launchMethod: LaunchMethod) => {
      if (displayOnly) {
        return;
      }
      onLaunch(launchMethod);
      onSelectItem(selectionId);
    },
    [onLaunch, onSelectItem, selectionId, displayOnly],
  );

  const handleAuxClick = useCallback(
    (e: React.MouseEvent) => {
      // 1 is middle click (scroll click), behavior should mirror command_click
      // for all other aux click buttons (ex: right click) we want to do nothing
      if (e.button === AUX_CLICK_BUTTON.MIDDLE) {
        handleOpenItem('click');
        e.preventDefault();
      }
    },
    [handleOpenItem],
  );

  const moveUpOrDown = useCallback<Handler>(() => {
    const container = containerRef?.current;
    const { activeElement } = document;
    if (container?.contains(activeElement) && container !== activeElement) {
      container?.focus();
    }
  }, []);

  const copy = useCallback<Handler>(() => {
    if (stringForClipboard) {
      copyToClipboard(stringForClipboard);
      showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
    }

    onCopy?.();
  }, [stringForClipboard, onCopy]);

  const launch = useCallback<Handler>(() => {
    const container = containerRef?.current;
    const { activeElement } = document;
    // ensure focus isn't on result action or dropdown
    if (container === activeElement) {
      handleOpenItem('enter');
    }
  }, [handleOpenItem]);

  const keyHandlers = useMemo<Handlers<'resultItem'>>(
    () => ({
      moveUp: moveUpOrDown,
      moveDown: moveUpOrDown,
      launch,
      copy,
      addToStack: () => onAddToStack?.(),
      summarize: () => onSummarize?.(),
    }),
    [moveUpOrDown, copy, launch, onAddToStack, onSummarize],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (displayOnly) {
        return;
      }

      const keyMap = getKeyMaps(platform, isDesktop).resultItem;
      const osModifiers = getOsModifierKeys(platform);
      const mapEventToMousetrap = getMousetrapMap(platform);

      hotkeys(event, keyMap, keyHandlers, {
        osModifiers,
        mapEventToMousetrap,
      });
    },
    [keyHandlers, platform, isDesktop, displayOnly],
  );

  const dataAttributes = {
    [DATA_ATTRIBUTE_SELECTION_ID]: selectionId,
    [DATA_ATTRIBUTE_SELECTED]: selected,
  };

  const handleShowVerifiedPopup = () => {
    if (verifiedTimeoutRef.current) {
      clearTimeout(verifiedTimeoutRef.current);
      verifiedTimeoutRef.current = null;
    }
    setOpenVerified(true);
  };

  const handleHideVerifiedPopup = () => {
    verifiedTimeoutRef.current = setTimeout(() => {
      setOpenVerified(false);
    }, 50);
    // 50ms is just enough for the user to move cursor to the tooltip
  };

  return (
    <li className={styles.listItemContainer}>
      <div
        tabIndex={0}
        role="link"
        ref={containerRef}
        data-x-uuid={debugUuid}
        className={classNames(
          styles.container,
          'cursor-override', // Allows hornet to override the cursor behavior
          {
            [styles.selected]: selected,
            [styles.colorSubtle]: colorVariant === 'subtle',
            [styles.hovered]: showHoverState,
            [styles.isMobile]: isMobileSize,
            [styles.isDesktop]: isDesktop,
            [styles.animateIn]: animate,
            [styles.noMargin]: noMargin,
          },
        )}
        style={{ animationDelay: `${delayMs}ms` }}
        onMouseOver={() => setHovered(true)}
        onFocus={(e) => {
          // If the container gains focus, mark it as selected for keyboard nav
          if (containerRef.current === e.target) {
            onSelectItem(selectionId);
          }
        }}
        onMouseLeave={() => setHovered(false)}
        onClick={() => handleOpenItem('click')}
        onAuxClick={handleAuxClick}
        onKeyDown={handleKeyDown}
        {...dataAttributes}
      >
        <div className={styles.topRow}>
          <div
            className={classNames(styles.iconContainer, {
              [styles.colorSubtle]: colorVariant === 'subtle',
            })}
          >
            {icon}
          </div>
          <div className={styles.textContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.titleTextWrapper}>
                <Text
                  variant="label"
                  size={isDesktop ? 'medium' : 'large'}
                  className={classNames(styles.titleText, {
                    [styles.colorSubtle]: colorVariant === 'subtle',
                  })}
                >
                  <TitleHighlighter
                    textSize={isDesktop ? 'medium' : 'large'}
                    query={query}
                    title={title}
                  />
                </Text>
                {verifiedSearchResultsEnabled && verification && (
                  <>
                    {openVerified && (
                      <VerifiedPopup
                        verification={verification}
                        anchorRef={verifiedRef}
                        onMouseEnter={handleShowVerifiedPopup}
                        onMouseLeave={handleHideVerifiedPopup}
                        onFocus={handleShowVerifiedPopup}
                        onBlur={handleHideVerifiedPopup}
                        resultUuid={resultUuid}
                      />
                    )}
                    <div
                      ref={verifiedRef}
                      onMouseEnter={handleShowVerifiedPopup}
                      onMouseLeave={handleHideVerifiedPopup}
                      className={styles.verifiedIcon}
                      aria-label={i18n.t('verified_search_result')}
                      aria-describedby="verified-search-result-tooltip"
                      onFocus={handleShowVerifiedPopup}
                      onBlur={handleHideVerifiedPopup}
                      role="button"
                      tabIndex={0}
                    >
                      <UIIcon src={VerifiedFill} />
                    </div>
                  </>
                )}
              </div>
            </div>
            {subtext}
          </div>
          <div
            className={classNames(styles.actions, {
              [styles.alignTop]: alignActionsTop,
            })}
          >
            <ResultActions
              displayedActions={displayedActions}
              dropdownActions={dropdownActions}
              isResultSelected={selected}
              onOpenDropdown={onOpenDropdown}
              showResultActions={showResultActions}
            />
          </div>
        </div>

        <div ref={bodyRef}>
          {bodyContent && (
            <div
              className={classNames(styles.snippetText, {
                [styles.removeMargins]: !hasBodyContent,
              })}
            >
              {bodyContent}
            </div>
          )}
        </div>

        {isMobileSize && (
          <ResultActions
            displayedActions={displayedActions}
            dropdownActions={dropdownActions}
            onOpenDropdown={onOpenDropdown}
            isResultSelected={selected}
          />
        )}
      </div>
    </li>
  );
};

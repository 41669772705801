import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Edit_VoiceMenuOption } from '@mirage/analytics/events/types/edit_voice_menu_option';
import { PAP_Send_ComposePrompt } from '@mirage/analytics/events/types/send_compose_prompt';
import { useComposeSourcesCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { tagged } from '@mirage/service-logging';
import {
  ComposeAssistantConversationMessage,
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import {
  ComposeVoice,
  getVoiceModificationHistoryFromMessages,
} from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import debounce from 'lodash.debounce';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { getAssistantResponse } from '../../data/llm/llm';
import { AssistantResponse } from '../../data/llm/llm-types';

const logger = tagged('useVoiceSettings');

interface VoiceSettingsParams {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  initialVoiceID: string | undefined;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  onDone: () => void;
}
export const useVoiceSettings = ({
  voices,
  saveVoice,
  initialVoiceID,
  logComposeEvent,
  onDone,
}: VoiceSettingsParams) => {
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedVoiceID, setSelectedVoiceID] = useState<string | undefined>(
    initialVoiceID,
  );
  const currentVoice = selectedVoiceID
    ? voices.find((voice) => voice.id === selectedVoiceID)
    : undefined;
  const [currentVoiceState, setCurrentVoiceState] = useState<ComposeVoice>(
    currentVoice || {
      id: uuid4(),
      dataId: '',
      name: '',
      description: '',
      sources: [],
      messagesHistory: [],
      redraftedSamples: [],
      rev: 0,
    },
  );
  const sourcesContents = useComposeSourcesCache(
    currentVoiceState?.sources || [],
  );

  const handleAddSource = useCallback((source: ComposeSource) => {
    setCurrentVoiceState((prevVoice) => {
      if (prevVoice === undefined) return prevVoice;
      if (
        prevVoice.sources.some(
          (s) => getSourceUUID(s) === getSourceUUID(source),
        )
      ) {
        return prevVoice;
      }
      return {
        ...prevVoice,
        sources: [...prevVoice.sources, source],
      };
    });
  }, []);

  const handleRemoveSource = useCallback((source: ComposeSource) => {
    setCurrentVoiceState((prevVoice) => {
      if (prevVoice === undefined) return prevVoice;
      return {
        ...prevVoice,
        sources: prevVoice.sources.filter(
          (s) => getSourceUUID(s) !== getSourceUUID(source),
        ),
      };
    });
  }, []);

  const debouncedLogEvent = useMemo(() => {
    return debounce(
      () => {
        logComposeEvent(PAP_Edit_VoiceMenuOption());
      },
      1000,
      { trailing: true },
    );
  }, [logComposeEvent]);

  const handleChangeVoiceNameInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentVoiceState((prevVoice) => {
        if (prevVoice === undefined) return prevVoice;
        return {
          ...prevVoice,
          name: e.target.value,
        };
      });
      debouncedLogEvent();
    },
    [debouncedLogEvent],
  );

  const handleChangeVoiceDescriptionInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentVoiceState((prevVoice) => {
        if (prevVoice === undefined) return prevVoice;
        return {
          ...prevVoice,
          description: e.target.value,
        };
      });
      debouncedLogEvent();
    },
    [debouncedLogEvent],
  );

  const handleClickSave = useCallback(() => {
    if (currentVoiceState) {
      saveVoice({
        ...currentVoiceState,
        name: currentVoiceState.name || i18n.t('compose_new_tone'),
      });
      onDone();
    }
  }, [currentVoiceState, onDone, saveVoice]);

  const handleClickDelete = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  }, []);

  const handleModifyVoiceResponse = useCallback(
    (response: AssistantResponse) => {
      if (response.type !== 'modify_voice') {
        logger.error('Expected modify_voice response, got:', response);
        return;
      }

      const {
        redraftedSamples,
        followUpSuggestions,
        draftModifications,
        voiceName,
        voiceDescription,
      } = response;
      const newMessage: ComposeAssistantConversationMessage = {
        type: 'message',
        role: 'assistant',
        text: draftModifications || i18n.t('compose_generic_error'),
        ts: Date.now(),
        actionContext: { type: draftModifications ? 'done' : 'error' },
        followUpSuggestions,
      };

      setCurrentVoiceState((prevVoice) => {
        if (prevVoice === undefined) return prevVoice;
        return {
          ...prevVoice,
          name: voiceName,
          description: voiceDescription,
          redraftedSamples,
          messagesHistory: [...(prevVoice.messagesHistory || []), newMessage],
        };
      });
    },
    [],
  );

  const postInitialMessage = useCallback(() => {
    setCurrentVoiceState((prevVoice) => {
      if (prevVoice === undefined) return prevVoice;
      return {
        ...prevVoice,
        messagesHistory: [
          {
            type: 'message',
            role: 'assistant',
            text: i18n.t('compose_settings_initial_message'),
            ts: Date.now(),
            rawPromptText: '',
            actionContext: { type: 'done' },
          } as ComposeAssistantConversationMessage,
        ],
      };
    });
  }, []);

  const handleModifyVoicePostUserMessage = useCallback(
    (text = '') => {
      setLoading(true);
      const newMessage: ComposeAssistantConversationMessage = {
        type: 'message',
        role: 'user',
        text,
        ts: Date.now(),
        rawPromptText: '',
        actionContext: { type: 'done' },
      };

      if (text.length) {
        setCurrentVoiceState((prevVoice) => {
          if (prevVoice === undefined) return prevVoice;
          return {
            ...prevVoice,
            messagesHistory: [...(prevVoice.messagesHistory || []), newMessage],
          };
        });
      }

      logComposeEvent(
        PAP_Send_ComposePrompt({
          queryString: text,
          itemCount: currentVoiceState.messagesHistory?.length,
          queryLength: text.length,
        }),
        { actionSurfaceComponent: 'compose_voices_pane' },
      );

      getAssistantResponse(
        {
          newMessage,
          messagesHistory: currentVoiceState?.messagesHistory || [],
          sourcesContents,
          composeParams: undefined,
          modifyVoiceParams: {
            voiceID: currentVoiceState.id,
            voiceSourceContents: sourcesContents,
            voiceModificationHistory: getVoiceModificationHistoryFromMessages(
              currentVoiceState.messagesHistory || [],
            ),
          },
          mustIncludeSourceContents: true,
          featureFlags: {
            dashSearchEnabled: false,
          },
        },
        handleModifyVoiceResponse,
      )
        .then(() => {
          return setLoading(false);
        })
        .catch((e) => {
          logger.error('Failed to get assistant response', e);
          return setLoading(false);
        });
    },
    [
      currentVoiceState,
      sourcesContents,
      handleModifyVoiceResponse,
      logComposeEvent,
    ],
  );

  return {
    loading,
    setLoading,
    voices,
    selectedVoiceID,
    setSelectedVoiceID,
    currentVoiceState,
    setCurrentVoiceState,
    sourcesContents,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleAddSource,
    handleRemoveSource,
    handleChangeVoiceNameInput,
    handleChangeVoiceDescriptionInput,
    handleModifyVoicePostUserMessage,
    handleClickSave,
    handleClickDelete,
    postInitialMessage,
  };
};
